import React from "react";
import bappenas from "./assets/logo-bappenas.png";
import icctv from "./assets/Logo_LCDI.jpeg";
import styles from "./index.module.scss";

export const Footer = ({ toggleTentang }) => (
  <div className={styles.root}>
    <div className={styles.logo}>
      <div>
        <a href="https://www.bappenas.go.id/id/">
          <img src={bappenas} alt="Bappenas" width={107} />
        </a>
      </div>
      <div>
        <a href="https://www.lcdi-indonesia.id/">
          <img src={icctv} alt="ICCTF" width={80} />
        </a>
      </div>
    </div>
    <div className={styles.link}>
      <p className={styles.copyright}>
        2019 © Peta Gambut Indonesia |{" "}
        <a
          href="#TentangWeb"
          onClick={e => {
            e.preventDefault();
            toggleTentang();
          }}
        >
          Tentang Web
        </a>
      </p>
    </div>
  </div>
);

export default Footer;
