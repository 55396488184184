import React from 'react';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import styles from './index.module.scss';


const DragHandle = sortableHandle(() => <div className={styles.dragHandle} />);
const SortableItem = sortableElement(({ children }) => (
  <div className={styles.layerItem}>
    <DragHandle />
    {children}
  </div>
));
const SortableContainer = sortableContainer(({ children }) => (
  <div className={styles.layers}>{children}</div>
));

export const Sidebar = ({
  baseLayer,
  setBaseLayer,
  layersState, 
  layersDispatch,
}) => {
  
  return (
    <div className={styles.root}>
      <h1>Pemetaan Lahan Gambut Menggunakan Citra Satelit Aktif dan Pasif</h1>
      <div>
        <SortableContainer
          onSortEnd={({oldIndex, newIndex}) => layersDispatch({
            type: 'replace',
            data: arrayMove(layersState, oldIndex, newIndex)
          })}
          lockAxis="y"
          helperClass={styles.layerDragged}
          useDragHandle
        >
          {layersState.map((layer, index) => (
            <SortableItem key={`item-${layer.id}`} index={index}>
              <div
                className={`${styles.layerName} ${layer.visible ? styles.layerNameActive : ''}`}
              >
                <h5>{layer.name}</h5>
                {layer.visible && (
                  <>
                    <div className={styles.layerDescription}>
                      {layer.description && (<p>{layer.description}</p>)}
                    </div>
                    {layer.legends}
                  </>
                )}
              </div>
              <div
                className={`${styles.layerToggle} ${layer.visible ? styles.layerToggleActive : ''}`}
                onClick={() => layersDispatch({ type: 'toggle', id: layer.id})}
              />
            </SortableItem>
          ))}
        </SortableContainer>
      </div>
      <div className={styles.baseLayer}>
        <div
          className={`${styles.baseLayerItem} ${baseLayer === 'Light' ? styles.baseLayerItemSelected : ''}`}
          onClick={() => setBaseLayer('Light')}
        >
          <div className={`${styles.baseLayerItemImage} ${styles.baseLayerItemImageLight}`}></div>
          <div className={styles.baseLayerItemText}>Light</div>
        </div>
        <div
          className={`${styles.baseLayerItem} ${baseLayer === 'Street' ? styles.baseLayerItemSelected : ''}`}
          onClick={() => setBaseLayer('Street')}
        >
          <div className={`${styles.baseLayerItemImage} ${styles.baseLayerItemImageStreet}`}></div>
          <div className={styles.baseLayerItemText}>Street</div>
        </div>
        <div
          className={`${styles.baseLayerItem} ${baseLayer === 'Satellite' ? styles.baseLayerItemSelected : ''}`}
          onClick={() => setBaseLayer('Satellite')}
        >
          <div className={`${styles.baseLayerItemImage} ${styles.baseLayerItemImageSatellite}`}></div>
          <div className={styles.baseLayerItemText}>Satellite</div>
        </div>
        <div
          className={`${styles.baseLayerItem} ${baseLayer === 'Terrain' ? styles.baseLayerItemSelected : ''}`}
          onClick={() => setBaseLayer('Terrain')}
        >
          <div className={`${styles.baseLayerItemImage} ${styles.baseLayerItemImageTerrain}`}></div>
          <div className={styles.baseLayerItemText}>Terrain</div>
        </div>
      </div>
    </div>
  )
};

export default Sidebar;