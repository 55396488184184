import React from "react";
import styles from "./index.module.scss";

export const Tentang = ({ visible, toggleVisible }) => {
  if (!visible) return null;
  return (
    <div className={styles.root} onClick={e => toggleVisible()}>
      <div className={styles.inner} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <h2>Tentang Web</h2>
          <a
            href="#close"
            onClick={e => {
              e.preventDefault();
              toggleVisible();
            }}
          >
            Close
          </a>
        </div>
        <div className={styles.content}>
          <p>
            <strong>Peta Daring atau Web GIS Gambut Indonesia</strong> ini
            adalah bagian dari kegiatan{" "}
            <strong>
              Pemetaan dan Analisis Lahan Gambut di Indonesia Menggunakan Citra
              Satelit Aktif dan Pasif
            </strong>{" "}
            yang dilaksanakan oleh PT Agrisoft Citra Buana. Kegiatan ini
            dibiayai dan menjadi salah satu program unggulan ICCTF yang 
            dilakukan di Indonesia terkait mitigasi perubahan iklim berbasis
            lahan (ekosistem gambut).{" "}
          </p>

          <p>
            <em>Indonesia Climate Change Trust Fund</em> (ICCTF) merupakan
            Satuan Kerja di bawah Kementerian Perencanaan Pembangunan Nasional/
            BAPPENAS yang dibentuk pada tahun 2009 untuk mendukung pemerintah
            dalam menanggulangi perubahan iklim. ICCTF memiliki mandat sebagai
            Lembaga Wali Amanat Nasional (<em>National Trust Fund</em>) untuk
            memobilisasi dan mengelola pendanaan yang bersumber dari mitra
            pembangunan internasional, sektor publik dan swasta untuk digunakan
            bagi pembiayaan program mitigasi dan adaptasi perubahan iklim yang
            selaras dengan prioritas dan target pembangunan nasional. Dalam
            melaksanakan tugasnya, ICCTF memiliki 4 (empat) <em>windows</em>,
            yaitu: i) mitigasi berbasis lahan (
            <em>land-based mitigation window</em>); ii) adaptasi dan ketahanan (
            <em>adaptation and resilience window</em>); iii) energi terbarukan
            dan energi efisiensi (
            <em>renewable energy and energy efficiency window</em>); dan iv)
            kelautan (marine <em>window</em>).
          </p>

          <p>
            Pada kegiatan ini dilakukan penyediaan data dan informasi lahan
            gambut yang menyeluruh dan mutakhir melalui proses pemetaan dan
            analisis spasial menggunakan citra satelit aktif dan pasif, data
            survei lapangan, dan beberapa data pendukung seperti data ketinggian
            dan data peta rujukan dari wali data terkait. Proses pembuatan peta
            ini dilakukan dengan menyesuaikan skala luaran akhir yaitu pada
            skala spasial 1:100.000.
          </p>
          <h3>Penyangkalan (Disclaimer)</h3>
          <p>
            Peta daring ini dibuat berdasarkan peta gambut Indonesia yang
            dibangun berdasarkan analisis model spasial. Data dasar yang
            digunakan adalah citra satelit Sentinel-1, Landsat 8 OLI, DEM SRTM,
            dan Peta Rupabumi Indonesia (RBI). Pendekatan hasil adalah melalui
            berbagai model dengan tetap pada koridor kelayakan analisis spasial
            untuk menghasilkan luaran peta pada skala 1:100.000.
          </p>

          <p>
            Ketidaktepatan pada kondisi lapangan dapat terjadi karena hasil ini
            sepenuhnya merupakan pendekatan model dan tidak diperkuat dengan
            validasi langsung menggunakan metode konvensional (seperti bor
            tanah) yang selama ini dilakukan oleh berbagai pihak.
          </p>
          <p>
            <strong>Pelaksana Kegiatan</strong>
            <br />
            PT. Agrisoft Citra Buana
            <br />
            Jl. Achmad Adnawijaya No. 162A
            <br />
            Bogor - Jawa Barat 16154
          </p>
          <p>
            <strong>Tim Pelaksana</strong> <br />
            Aslan, PhD.
            <br />
            Dr. Firman Hadi
            <br />
            Iwan Setiawan, PM. <br />
            Selamet Kusdaryanto, M.Si.
            <br />
            Dr. Kholil <br />
            Armaiki Yusmur, MSi.
            <br />
            Nanda Yadessa, S.Kom
          </p>
        </div>
      </div>
    </div>
  );
};

export default Tentang;
