import React, { useState, useReducer } from "react";
import fetch from "isomorphic-unfetch";
import {
  Map,
  TileLayer,
  ZoomControl,
  ScaleControl,
  Popup
} from "react-leaflet";
import { Sidebar } from "./components/sidebar";
import { Footer } from "./components/footer";
import { Tentang } from "./components/tentang";
import "./leaflet.css";
import styles from "./index.module.scss";
import { ReactComponent as IconGambut } from "./images/gambut.svg";
import { ReactComponent as IconMineral } from "./images/mineral.svg";
import { ReactComponent as IconPantau } from "./images/menara_pantau.svg";
import { ReactComponent as IconEkowisata } from "./images/ekowisata.svg";
import { ReactComponent as IconRevegetasi } from "./images/revegetasi.svg";
import { ReactComponent as IconDemplot } from "./images/demplot.svg";
import { ReactComponent as IconSumurBor } from "./images/sumur_bor.svg";
import { ReactComponent as IconPenimbunanKanal } from "./images/penimbunan_kanal.svg";
import { ReactComponent as IconSekatKanal } from "./images/sekat_kanal.svg";

let requestId = 0;
let displayedRequestId = 0;
// const token = 'pk.eyJ1IjoibXV0dWNhcmUiLCJhIjoiY2pzN2l5NWp4MGJmdTQ0czUyMmN1Y3RjMCJ9.efu-NqyNHGhUGgVKY4rL4g';
const token =
  "pk.eyJ1IjoiZ2FtYnV0c2F0ZWxpdCIsImEiOiJjazJ4MGZocjEwNzNpM2lvN3lua2J2cDdyIn0.ZujokR0SJJg7XF6AuafKEQ";
const baseLayerConfig = {
  Street: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token=${token}`,
  Satellite: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=${token}`,
  Terrain: `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/256/{z}/{x}/{y}?access_token=${token}`,
  Light: `https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}?access_token=${token}`,
  Dark: `https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/256/{z}/{x}/{y}?access_token=${token}`
};

const initialLayersState = [
  {
    id: 6,
    visible: false,
    index: 6,
    name: "Observasi",
    url: [
      `https://api.mapbox.com/styles/v1/gambutsatelit/ck38smvl808gq1dkum8uzj8uq/tiles/256/{z}/{x}/{y}?access_token=${token}`
    ],
    identify: true,
    tilequery: "gambutsatelit.1lv2qjdi",
    identifyRadiusInMeters: 20000,
    description:
      "Observasi insitu pada wilayah Kabupaten Siak (Riau), Kabupaten Ketapang (Kalimantan Barat), Kabupaten Bintuni (Papua Barat) dan Kabupaten Mimika (Papua)",
    legends: (
      <div className={styles.layerLegends}>
        <div>
          <div className={styles.legendItem}>
            <div className={styles.legendStyle}>
              <IconGambut />
            </div>
            <div className={styles.legendLabel}>Gambut</div>
          </div>
        </div>
        <div>
          <div className={styles.legendItem}>
            <div className={styles.legendStyle}>
              <IconMineral />
            </div>
            <div className={styles.legendLabel}>Mineral</div>
          </div>
        </div>
      </div>
    )
  },
  {
    id: 5,
    visible: false,
    index: 5,
    name: "Kegiatan Proponen",
    url: [
      `https://api.mapbox.com/styles/v1/gambutsatelit/ck38svbwv1rsz1ct3y1becia1/tiles/256/{z}/{x}/{y}?access_token=${token}`
    ],
    identify: true,
    tilequery: "gambutsatelit.5yez0pbf",
    identifyRadiusInMeters: 20000,
    description:
      "Titik koordinat kegiatan fisik proponen program ICCTF, UKCCU Batch 2, Tahun 2018 - 2019.",
    legends: (
      <div className={styles.layerLegends}>
        <div>
          <div className={styles.legendItem}>
            <div className={styles.legendStyle}>
              <IconPantau />
            </div>
            <div className={styles.legendLabel}>Menara Pantau</div>
          </div>
          <div className={styles.legendItem}>
            <div className={styles.legendStyle}>
              <IconEkowisata />
            </div>
            <div className={styles.legendLabel}>Ekowisata Mangrove</div>
          </div>
          <div className={styles.legendItem}>
            <div className={styles.legendStyle}>
              <IconRevegetasi />
            </div>
            <div className={styles.legendLabel}>Revegetasi/Pengkayaan</div>
          </div>
          <div className={styles.legendItem}>
            <div className={styles.legendStyle}>
              <IconDemplot />
            </div>
            <div className={styles.legendLabel}>Demplot Agroforestry</div>
          </div>
        </div>
        <div>
          <div className={styles.legendItem}>
            <div className={styles.legendStyle}>
              <IconSumurBor />
            </div>
            <div className={styles.legendLabel}>Sumur Bor</div>
          </div>
          <div className={styles.legendItem}>
            <div className={styles.legendStyle}>
              <IconPenimbunanKanal />
            </div>
            <div className={styles.legendLabel}>Penimbunan Kanal</div>
          </div>
          <div className={styles.legendItem}>
            <div className={styles.legendStyle}>
              <IconSekatKanal />
            </div>
            <div className={styles.legendLabel}>Sekat Kanal</div>
          </div>
        </div>
      </div>
    )
  },
  {
    id: 4,
    visible: false,
    index: 4,
    name: "Lahan Gambut (Bappenas, 2019)",
    url: [
      `https://a.tiles.mapbox.com/v4/gambutsatelit.5yez0pbf/{z}/{x}/{y}.png?access_token=${token}`,
      `https://a.tiles.mapbox.com/v4/gambutsatelit.bt0qjgqp/{z}/{x}/{y}.png?access_token=${token}`,
      `https://a.tiles.mapbox.com/v4/gambutsatelit.09e51qw5/{z}/{x}/{y}.png?access_token=${token}`,
      `https://a.tiles.mapbox.com/v4/gambutsatelit.aja2nkhh/{z}/{x}/{y}.png?access_token=${token}`
    ],
    identify: false,
    description:
      "Sebaran lahan gambut hasil Studi Pemetaan Lahan Gambut Menggunakan Citra Satelit Aktif dan Pasif (Bappenas/ICCTF, 2019)",
    legends: (
      <div className={styles.layerLegends}>
        <div>
          <div className={styles.legendItem}>
            <div
              style={{ background: "#e709ff" }}
              className={styles.legendStyle}
            ></div>
            <div className={styles.legendLabel}>Lahan Gambut</div>
          </div>
        </div>
      </div>
    )
  },
  {
    id: 3,
    visible: false,
    index: 3,
    name: "Lahan Gambut (Gumbricht, 2017)",
    url: [
      `https://a.tiles.mapbox.com/v4/gambutsatelit.dcrtezzt/{z}/{x}/{y}.png?access_token=${token}`
    ],
    identify: false,
    description: "Sebaran lahan gambut di Indonesia.",
    legends: (
      <div className={styles.layerLegends}>
        <div>
          <div className={styles.legendItem}>
            <div
              style={{ background: "#FF5429" }}
              className={styles.legendStyle}
            ></div>
            <div className={styles.legendLabel}>Lahan Gambut</div>
          </div>
        </div>
      </div>
    )
  },
  {
    id: 2,
    visible: false,
    index: 2,
    name: "Lahan Gambut (BBSDLP, 2011)",
    url: [
      `https://api.mapbox.com/styles/v1/gambutsatelit/ck2x3atlz1dcy1dtdg52wg71u/tiles/256/{z}/{x}/{y}?access_token=${token}`
    ],
    identify: false,
    tilequery: "gambutsatelit.3vfi7oev",
    identifyRadiusInMeters: 0,
    description: "Sebaran lahan gambut di Indonesia.",
    legends: (
      <div className={styles.layerLegends}>
        <div>
          <div className={styles.legendItem}>
            <div
              style={{ background: "#d0de3b" }}
              className={styles.legendStyle}
            ></div>
            <div className={styles.legendLabel}>Lahan Gambut</div>
          </div>
        </div>
      </div>
    )
  },
  {
    id: 1,
    visible: false,
    index: 1,
    name: "Kesatuan Hidrologis Gambut (KHG)",
    url: [
      `https://api.mapbox.com/styles/v1/gambutsatelit/ck2x2v2jf0i921cmwvzhsiros/tiles/256/{z}/{x}/{y}?access_token=${token}`
    ],
    identify: true,
    tilequery: "gambutsatelit.2hodkqd3",
    identifyRadiusInMeters: 0,
    description:
      "Batas Kesatuan Hidrologis Gambut (KHG) berdasarkan SK Menteri LHK No. 129 Tahun 2017. KHG adalah ekosistem gambut yang terletak di antara dua sungai, di antara sungai dan laut, dan/atau pada rawa.",
    legends: (
      <div className={styles.layerLegends}>
        <div>
          <div className={styles.legendItem}>
            <div
              style={{ background: "#C7E6DC" }}
              className={styles.legendStyle}
            ></div>
            <div className={styles.legendLabel}>Lintas Kabupaten/Kota</div>
          </div>
          <div className={styles.legendItem}>
            <div
              style={{ background: "#81BBD7" }}
              className={styles.legendStyle}
            ></div>
            <div className={styles.legendLabel}>Lintas Provinsi</div>
          </div>
        </div>
        <div>
          <div className={styles.legendItem}>
            <div
              style={{ background: "#EBC9BB" }}
              className={styles.legendStyle}
            ></div>
            <div className={styles.legendLabel}>Kabupaten/Kota</div>
          </div>
        </div>
      </div>
    )
  }
];
const layersReducer = (state, action) => {
  switch (action.type) {
    case "toggle":
      return state.map(layer => {
        if (action.id === layer.id) {
          return Object.assign({}, layer, {
            visible: !layer.visible
          });
        }
        return layer;
      });
    case "replace":
      return action.data;
    default:
      throw new Error();
  }
};

const MapComponent = () => {
  const [baseLayer, setBaseLayer] = useState("Light");
  const [layersState, layersDispatch] = useReducer(
    layersReducer,
    initialLayersState
  );
  const [identifyPopup, setIdentifyPopup] = useState();
  const [identifyLoading, setIdentifyLoading] = useState();
  const [identifyData, setIdentifyData] = useState();
  const [tentangVisibility, setTentangVisibility] = useState(false);

  return (
    <div className={styles.map}>
      <Tentang
        visible={tentangVisibility}
        toggleVisible={() => setTentangVisibility(!tentangVisibility)}
      />
      <Sidebar
        baseLayer={baseLayer}
        setBaseLayer={setBaseLayer}
        layersState={layersState}
        layersDispatch={layersDispatch}
      />
      <Footer toggleTentang={() => setTentangVisibility(!tentangVisibility)} />
      <div className={styles.mapContainer}>
        <Map
          style={{ width: "100%", height: "100%" }}
          zoom={5}
          maxZoom={17}
          center={[-2.9199621, 119.4805323]}
          zoomControl={false}
          whenReady={e => {
            e.target.flyToBounds(
              [
                [-10.3599874813, 95.2930261576],
                [5.47982086834, 141.03385176]
              ],
              {
                paddingTopLeft: [360, 0]
              }
            );
          }}
          onClick={e => {
            if (identifyPopup) {
              setIdentifyLoading(null);
              setIdentifyData(null);
              setIdentifyPopup(null);
            } else {
              setIdentifyLoading(null);
              setIdentifyData(null);
              let hasIdentifiableLayer = false;
              layersState.map(layer => {
                if (layer.visible) hasIdentifiableLayer = true;
              });
              if (hasIdentifiableLayer) {
                setIdentifyPopup([e.latlng.lat, e.latlng.lng]);
              }
            }
          }}
        >
          {identifyPopup && (
            <Popup
              key={new Date().toString()}
              position={identifyPopup}
              closeButton={false}
            >
              <h3 className={styles.popupLayerHeader}>Identify</h3>
              {identifyLoading && <div className={styles.popupLoading} />}
              {identifyData && identifyData.features.length === 0 && (
                <div className={styles.noDataPopup}>
                  No data in this location.
                </div>
              )}
              {identifyData && identifyData.features.length > 0 && (
                <div className={styles.popupData}>
                  <table>
                    {Object.keys(identifyData.features[0].properties).map(
                      key => {
                        if (key !== "fid" && key !== "tilequery") {
                          return (
                            <tr key={key}>
                              <th>{key}</th>
                              <td>
                                {identifyData.features[0].properties[key]}
                              </td>
                            </tr>
                          );
                        }
                      }
                    )}
                  </table>
                </div>
              )}
              {!identifyLoading &&
                !identifyData &&
                layersState.map(layer => {
                  if (layer.visible) {
                    return (
                      <div
                        className={
                          layer.identify
                            ? styles.popupLayerItem
                            : styles.popupLayerItemDisabled
                        }
                        key={`popup-layer-${layer.id}`}
                        onClick={() => {
                          if (layer.identify) {
                            setIdentifyLoading(true);
                            const url = `https://api.mapbox.com/v4/${layer.tilequery}/tilequery/${identifyPopup[1]},${identifyPopup[0]}.json?radius=${layer.identifyRadiusInMeters}&access_token=${token}`;
                            requestId++;
                            fetch(url)
                              .then(r => r.json())
                              .then(data => {
                                if (requestId < displayedRequestId)
                                  return false;
                                displayedRequestId = requestId;
                                if (data.features) {
                                  setIdentifyData(data);
                                } else {
                                  setIdentifyData({ features: [] });
                                }
                                setIdentifyLoading(false);
                              });
                          }
                        }}
                      >
                        <span className={styles.popupIcon} />
                        <span>{layer.name}</span>
                      </div>
                    );
                  }
                  return null;
                })}
            </Popup>
          )}
          <ZoomControl position="topright" />
          <ScaleControl position="bottomright" />
          <TileLayer url={baseLayerConfig[baseLayer]} />
          {layersState.map((layer, index) => {
            if (layer.visible) {
              return layer.url.map((urlItem, urlIndex) => {
                console.log("urlItem", urlItem);
                return (
                  <TileLayer
                    key={`${layer.id}-${urlIndex}`}
                    zIndex={layersState.length - index}
                    tileSize={256}
                    opacity={0.7}
                    url={urlItem}
                  />
                );
              });
            }
            return null;
          })}
          )}
        </Map>
      </div>
    </div>
  );
};

export default MapComponent;
